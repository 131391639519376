import React, { useState, createRef, RefObject, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { motion, AnimatePresence } from 'framer-motion';
import { graphql } from 'gatsby';

import {
    layout,
    hero,
    list,
    section,
    modal,
    modalCard,
    modalLayerButton,
    expanded,
} from './functionalities-alterpress.module.scss';
import { IAlterpressFunctionality } from '../models/alterpress-functionality.model';
import { IQueryAllResult } from '../models/query-all-result.model';
import { useT } from '../hooks/use-translation';
import { getNodes } from '../utils/get-nodes';

import MainLayout from '../layouts/main-layout';
import Hero from '../components/organisms/hero';
import SectionEcommerceNavigation from '../components/organisms/section-ecommerce-navigation';
import Section from '../components/hoc/section';
import FunctionalityCard from '../components/molecules/functionality-card';

interface IFunctionalitiesAlterpressPageProps {
    readonly data: {
        allAlterpressFunctionality: IQueryAllResult<IAlterpressFunctionality>;
    };
}

const FunctionalitiesAlterpressPage: React.FC<IFunctionalitiesAlterpressPageProps> = ({ data }) => {
    const { t } = useT();
    const { allAlterpressFunctionality } = data;
    const functionalities = getNodes(allAlterpressFunctionality);

    const listRef = useRef<HTMLUListElement | null>(null);
    const animationModeRef = useRef<'enter' | 'exit'>('enter');

    const [expandedItem, setExpandedItem] = useState<number | null>(null);
    const [initialPos, setInitialPos] = useState<{ top: number; left: number } | null>(null);
    const [initialWidth, setInitialWidth] = useState<number | null>(null);
    const [targetPos, setTargetPos] = useState<{ top: number; left: number } | null>(null);
    const [targetWidth, setTargetWidth] = useState<number | null>(null);
    const [isBodyOverflowHidden, setIsBodyOverflowHidden] = useState(false);

    const handleOpenModal = (index: number, cardRef: RefObject<HTMLLIElement | null>) => {
        return () => {
            const cardEl = cardRef.current;
            const listEl = listRef.current;
            if (!cardEl || !listEl) return;
            setExpandedItem(index);
            setIsBodyOverflowHidden(true);
            animationModeRef.current = 'enter';
            setTimeout(() => {
                const left = cardEl.getBoundingClientRect().left;
                const top = cardEl.getBoundingClientRect().top;
                const width = cardEl.getBoundingClientRect().width;
                setInitialPos({ left, top });
                setInitialWidth(width);
                const targetTop = 50;
                const targetLeft = listEl.getBoundingClientRect().left;
                const targetWidth = listEl.getBoundingClientRect().width;
                setTargetPos({ top: targetTop, left: targetLeft });
                setTargetWidth(targetWidth);
            }, 0);
        };
    };

    const handleCloseModal = () => {
        animationModeRef.current = 'exit';
        setExpandedItem(null);
    };

    const handleAnimationComplete = (animation: { name?: string }) => {
        if (animation.name === 'exit') {
            setIsBodyOverflowHidden(false);
            setInitialPos(null);
            setInitialWidth(null);
            setTargetPos(null);
            setTargetWidth(null);
        }
    };

    return (
        <>
            {isBodyOverflowHidden && (
                <Helmet
                    bodyAttributes={{
                        'data-overflow': 'hidden',
                    }}
                />
            )}
            <MainLayout className={layout} showFloatingCircles={false}>
                <Hero
                    className={hero}
                    titleProps={{ children: t('functionalities.hero.title'), Tag: 'h1' }}
                    color="blue"
                />
                <SectionEcommerceNavigation sectionProps={{ theme: 'dark' }} />
                <Section
                    className={section}
                    theme="dark"
                    circleDisplay="none"
                    contentDisplay="left"
                    height="auto"
                >
                    <ul ref={listRef} className={list}>
                        {functionalities.map((functionality, index) => {
                            const cardRef = createRef<HTMLLIElement>();
                            return (
                                <li
                                    ref={cardRef}
                                    key={`item-${functionality.functionalityId}`}
                                    className={index === expandedItem ? expanded : ''}
                                >
                                    <FunctionalityCard
                                        functionality={functionality}
                                        onShowMore={handleOpenModal(index, cardRef)}
                                    />
                                </li>
                            );
                        })}
                    </ul>
                </Section>
                <AnimatePresence>
                    {expandedItem !== null &&
                        initialPos &&
                        initialWidth &&
                        targetWidth &&
                        targetPos && (
                            <motion.div
                                key="functionality-modal"
                                className={modal}
                                initial={{ backdropFilter: `blur(0)` }}
                                animate={{
                                    backdropFilter: `blur(5px)`,
                                    transition: {
                                        duration: 0.3,
                                        delay: 0,
                                    },
                                }}
                                exit={{
                                    backdropFilter: `blur(0)`,
                                    name: 'exit',
                                    transition: {
                                        duration: 0.3,
                                        delay: 0.6,
                                    },
                                }}
                                onAnimationComplete={handleAnimationComplete}
                            >
                                <button
                                    onClick={handleCloseModal}
                                    tabIndex={-1}
                                    className={modalLayerButton}
                                />
                                <motion.div
                                    key="functionality-modal-card"
                                    initial={{
                                        position: 'absolute',
                                        width: initialWidth,
                                        ...initialPos,
                                    }}
                                    animate={{
                                        width: targetWidth,
                                        ...targetPos,
                                        transition: {
                                            top: {
                                                duration: 0.3,
                                                delay: 0.3,
                                            },
                                            left: {
                                                duration: 0.3,
                                                delay: 0.6,
                                            },
                                            width: {
                                                duration: 0.3,
                                                delay: 0.6,
                                            },
                                        },
                                    }}
                                    exit={{
                                        width: initialWidth,
                                        ...initialPos,
                                        transition: {
                                            top: {
                                                duration: 0.3,
                                                delay: 0.3,
                                            },
                                            left: {
                                                duration: 0.3,
                                                delay: 0,
                                            },
                                            width: {
                                                duration: 0.3,
                                                delay: 0,
                                            },
                                        },
                                    }}
                                >
                                    <FunctionalityCard
                                        className={modalCard}
                                        functionality={functionalities[expandedItem]}
                                        isModal={true}
                                        onShowLess={handleCloseModal}
                                    />
                                </motion.div>
                            </motion.div>
                        )}
                </AnimatePresence>
                <SectionEcommerceNavigation sectionProps={{ theme: 'dark' }} />
            </MainLayout>
        </>
    );
};

export const query = graphql`
    query ($locale: String!) {
        allAlterpressFunctionality(
            filter: { locale: { eq: $locale } }
            sort: { fields: sequence, order: ASC }
        ) {
            edges {
                node {
                    ...alterpressFunctionalityFields
                }
            }
        }
    }
`;

export default FunctionalitiesAlterpressPage;

export { Head } from '@alterpage/gatsby-plugin-alterpress-page-creator';
