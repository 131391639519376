import React, { useState } from 'react';
import AnimateHeight from 'react-animate-height';

import {
    container,
    header,
    name,
    button,
    iconBox,
    description,
    open,
} from './functionality-detail.module.scss';
import ArrowIcon from '../../assets/images/svg/arrow-left.svg';
import { IAlterpressFunctionalityModuleDetail } from '../../models/alterpress-functionality.model';

import Markdown from '../hoc/markdown';

interface IFunctionalityDetailProps {
    className?: string;
    NameTag?: React.ElementType;
    detail: IAlterpressFunctionalityModuleDetail;
    isInitiallyOpen?: boolean;
}

const FunctionalityDetail: React.FC<IFunctionalityDetailProps> = ({
    className = '',
    detail,
    NameTag = 'h2',
    isInitiallyOpen = false,
}) => {
    const [isOpen, setIsOpen] = useState(isInitiallyOpen);

    const handleToggle = () => {
        setIsOpen((prev) => !prev);
    };

    return (
        <div className={`${container} ${className} ${isOpen ? open : ''}`}>
            <div className={header}>
                <NameTag className={name}>{detail.name}</NameTag>
                <button className={button} onClick={handleToggle}>
                    <span className={iconBox}>
                        <ArrowIcon />
                    </span>
                </button>
            </div>
            <AnimateHeight height={isOpen ? 'auto' : 1}>
                <Markdown className={description}>{detail.description}</Markdown>
            </AnimateHeight>
        </div>
    );
};

export default FunctionalityDetail;
