import React, { useRef, useState } from 'react';
import { motion } from 'framer-motion';
import AnimateHeight from 'react-animate-height';

import {
    active,
    aside,
    asideButton,
    asideList,
    container,
    content,
    description,
    desktop,
    detailList,
    expanded,
    modal,
    header,
    image,
    innerContainer,
    mobile,
    moduleButton,
    moduleContent,
    moduleDescription,
    moduleHeader,
    moduleIconBox,
    moduleItem,
    moduleList,
    moduleName,
    name,
    ratio,
    toggleButton,
    toggleIcon,
    closeButtonBox,
    closeButton,
} from './functionality-card.module.scss';
import ArrowIcon from '../../assets/images/svg/arrow-left.svg';
import { IAlterpressFunctionality } from '../../models/alterpress-functionality.model';
import { useT } from '../../hooks/use-translation';
import { getLessImportantHeading } from '../../utils/get-less-important-heading';

import ImageWithErrorPlaceholder from '../atoms/image-with-error-placeholder';
import FunctionalityDetail from '../atoms/functionality-detail';
import CloseButton from '../atoms/close-button';

interface IFunctionalityCardProps {
    className?: string;
    functionality: IAlterpressFunctionality;
    TitleTag?: React.ElementType;
    isModal?: boolean;
    onShowMore?(): void;
    onShowLess?(): void;
}

const FunctionalityCard: React.FC<IFunctionalityCardProps> = ({
    className = '',
    functionality,
    TitleTag = 'h2',
    isModal = false,
    onShowMore,
    onShowLess,
}) => {
    const { t } = useT();
    const [activeModuleIndexDesktop, setActiveModuleIndexDesktop] = useState<number>(0);
    const [activeModuleIndexMobile, setActiveModuleIndexMobile] = useState<number | null>(null);
    const [isExpanded, setIsExpanded] = useState(false);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    const activeModuleDesktop = functionality.modules[activeModuleIndexDesktop];
    const ModuleNameTag = getLessImportantHeading(TitleTag) || 'h3';
    const DetailNameTag = getLessImportantHeading(ModuleNameTag) || 'h4';

    const handleModuleSelectDesktop = (index: number) => {
        return () => {
            setActiveModuleIndexDesktop(index);
        };
    };

    const handleModuleSelectMobile = (index: number) => {
        return () => {
            setActiveModuleIndexMobile(activeModuleIndexMobile === index ? null : index);
        };
    };

    const handleAnimationStart = () => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            setIsExpanded((prev) => !prev);
        }, 500);
    };

    return (
        <div
            className={`${container} ${className} ${isExpanded ? expanded : ''} ${
                isModal ? modal : ''
            }`}
        >
            {isModal && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 1 }}
                    className={closeButtonBox}
                >
                    <CloseButton className={closeButton} onClick={onShowLess} />
                </motion.div>
            )}
            <motion.div
                className={innerContainer}
                initial={{ opacity: 1 }}
                {...(isModal
                    ? {
                          animate: {
                              opacity: [1, 0, 0, 1],
                              transition: { duration: 1 },
                          },
                          exit: {
                              opacity: [1, 0, 0, 1],
                              transition: { duration: 1 },
                          },
                          onAnimationStart: handleAnimationStart,
                      }
                    : {})}
            >
                <div className={header}>
                    <TitleTag className={name}>{functionality.name}</TitleTag>
                    <ImageWithErrorPlaceholder
                        className={image}
                        media={functionality.media}
                        ratioClass={ratio}
                    />
                    <p className={description}>{functionality.description}</p>
                    <button className={toggleButton} onClick={isExpanded ? onShowLess : onShowMore}>
                        {isExpanded ? t('functionality.card.less') : t('functionality.card.more')}
                        <ArrowIcon className={toggleIcon} />
                    </button>
                </div>
                <motion.div
                    className={content}
                    {...(isModal
                        ? {
                              initial: { height: 0, opacity: 0 },
                              animate: {
                                  height: 'auto',
                                  opacity: 1,
                                  transition: { duration: 1 },
                              },
                              exit: {
                                  height: 0,
                                  opacity: 0,
                                  transition: { duration: 1 },
                              },
                          }
                        : {})}
                >
                    <aside className={aside}>
                        <ul className={asideList}>
                            {functionality.modules.map((module, index) => {
                                const isActive = index === activeModuleIndexDesktop;
                                return (
                                    <li key={`item-${index}`}>
                                        <button
                                            onClick={handleModuleSelectDesktop(index)}
                                            className={`${asideButton} ${isActive ? active : ''}`}
                                        >
                                            {module.name}
                                        </button>
                                    </li>
                                );
                            })}
                        </ul>
                    </aside>
                    {activeModuleDesktop && (
                        <ul className={`${moduleList} ${desktop}`}>
                            {functionality.modules.map((module, index) => {
                                const isActive = index === activeModuleIndexDesktop;
                                return (
                                    <li
                                        key={`module-${index}`}
                                        className={`${moduleItem} ${isActive ? active : ''}`}
                                    >
                                        <div className={moduleHeader}>
                                            <ModuleNameTag className={moduleName}>
                                                {module.name}
                                            </ModuleNameTag>
                                            <p className={moduleDescription}>
                                                {module.description}
                                            </p>
                                        </div>
                                        <ul className={detailList}>
                                            {module.details.map((detail, index) => {
                                                return (
                                                    <li key={`detail-${index}`}>
                                                        <FunctionalityDetail
                                                            NameTag={DetailNameTag}
                                                            detail={detail}
                                                            isInitiallyOpen={index === 0}
                                                        />
                                                    </li>
                                                );
                                            })}
                                        </ul>
                                    </li>
                                );
                            })}
                        </ul>
                    )}
                    <ul className={`${moduleList} ${mobile}`}>
                        {functionality.modules.map((module, index) => {
                            const isActive = index === activeModuleIndexMobile;
                            return (
                                <li
                                    key={`module-${index}`}
                                    className={`${moduleItem} ${isActive ? active : ''}`}
                                >
                                    <div className={moduleHeader}>
                                        <ModuleNameTag className={moduleName}>
                                            {module.name}
                                        </ModuleNameTag>
                                        <button
                                            className={moduleButton}
                                            onClick={handleModuleSelectMobile(index)}
                                        >
                                            <span className={moduleIconBox}>
                                                <ArrowIcon />
                                            </span>
                                        </button>
                                    </div>
                                    <AnimateHeight height={isActive ? 'auto' : 0}>
                                        <div className={moduleContent}>
                                            <p className={moduleDescription}>
                                                {module.description}
                                            </p>
                                            <ul className={detailList}>
                                                {module.details.map((detail, index) => {
                                                    return (
                                                        <li key={`detail-${index}`}>
                                                            <FunctionalityDetail
                                                                NameTag={DetailNameTag}
                                                                detail={detail}
                                                            />
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </div>
                                    </AnimateHeight>
                                </li>
                            );
                        })}
                    </ul>
                </motion.div>
            </motion.div>
        </div>
    );
};

export default FunctionalityCard;
