// extracted by mini-css-extract-plugin
export var active = "functionality-card-module--active--H8dBD";
export var aside = "functionality-card-module--aside--ASDgH";
export var asideButton = "functionality-card-module--aside-button--Hd4xY";
export var asideList = "functionality-card-module--aside-list--GA72W";
export var closeButton = "functionality-card-module--close-button--7idTn";
export var closeButtonBox = "functionality-card-module--close-button-box--cgad-";
export var container = "functionality-card-module--container--8yGNs";
export var content = "functionality-card-module--content--Zirn5";
export var description = "functionality-card-module--description--LKeV3";
export var desktop = "functionality-card-module--desktop--1O4qt";
export var detailList = "functionality-card-module--detail-list--UAis9";
export var expanded = "functionality-card-module--expanded--SjG-C";
export var header = "functionality-card-module--header--EsErh";
export var image = "functionality-card-module--image--ftGD3";
export var mobile = "functionality-card-module--mobile---wuVt";
export var modal = "functionality-card-module--modal--2Ad7s";
export var moduleButton = "functionality-card-module--module-button--AmJLR";
export var moduleContent = "functionality-card-module--module-content--VCPqS";
export var moduleDescription = "functionality-card-module--module-description--ofAH5";
export var moduleHeader = "functionality-card-module--module-header--Aq+pN";
export var moduleIconBox = "functionality-card-module--module-icon-box--g9zCx";
export var moduleItem = "functionality-card-module--module-item--1uCAH";
export var moduleList = "functionality-card-module--module-list--QJLNp";
export var moduleName = "functionality-card-module--module-name--nXA3h";
export var name = "functionality-card-module--name--wSHFQ";
export var ratio = "functionality-card-module--ratio--T7TSD";
export var toggleButton = "functionality-card-module--toggle-button--+pOMT";
export var toggleIcon = "functionality-card-module--toggle-icon--jmxnt";